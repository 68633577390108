import {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import './stylesheets/home/header.css';
import './stylesheets/home/introduction.css';
import './stylesheets/home/recent-news.css';
import './stylesheets/home/games.css';
import './stylesheets/home/contact.css';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {setTitle} from '../App.js';
import {formatTime} from '../scripts/formatTime';

const Home = () => {
  const [articledata, setArticleData] = useState();
  const [gamedata, setGameData] = useState();
  setTitle("Home");

  async function fetchArticles() {
    console.log(fetch('/data/articles.json', {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    },
    ).then(response => {
      if(response.ok) {
        response.json().then(json => {
          setArticleData(json);
        });
      }
    }
  ));
  }

  async function fetchGames() {
    console.log(fetch('/data/games.json', {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    },
    ).then(response => {
      if(response.ok) {
        response.json().then(json => {
          setGameData(json);
        });
      }
    }
  ));
  }

  useEffect(() => {
    fetchArticles();
    fetchGames();
  }, []);

  function playVideo() {
    var video = document.getElementById("game-clip");

    if(video.paused) video.play();
  }

  function pauseVideo() {
    var video = document.getElementById("game-clip");

    video.pause();
    video.currentTime = 0;
  }

  function displayArticle(articleid) {
    let icon = "/img/foonywoonygames/icon.png";
    if(articledata[articleid]['gameId'] != undefined) icon = "./img/game/" + articledata[articleid]['gameId'] + "/icon.png";
    return(
        <div className="news-thumbnail">
            <Link to={"/article/" + articleid}>
                <img src={"/img/thumbnail/" + articleid + ".png"} />
                <div className="news-thumbnail-overlay">
                    <span className="news-thumbnail-overlay-date">
                        {"Published " + formatTime(articledata[articleid]['publishDate'])}
                    </span>
                    <span className="news-thumbnail-overlay-title">
                        {articledata[articleid]['title']}
                    </span>
                </div>
                <img src={icon} className="news-thumbnail-icon" />
            </Link>
        </div>
    );
  }

  function displayGame(gameid) {
    return (
        <Link to="/arisen">
            <div className="game-container" onMouseOver={() => {playVideo()}} onMouseLeave={() => {pauseVideo()}}>
                <video autoplay muted loop id="game-clip">
                  <source src ="/clip/arisen.mp4" type="video/mp4" />
                </video>
                <img src={"./img/game/" + gameid + "/banner.png"}/>
                <div className="game-logo">
                    <img src={"/img/game/" + gameid + "/logo.png"}/>
                </div>
            </div>
        </Link>
    );
  }

  if(articledata === undefined) return (<h1>Loading</h1>)
  return (
    <div className="App">
      <header className="header">
        <img src="./img/foonywoonygames/logo.png" className="logo"/>
      </header>
      
      <div className="description">
        <div className="description-item">
            <h1>Welcome to Foony Woony Games</h1>
            <span>Here you will find all information related to Foony Woony Games such as games, projects, news and contact information.</span>
            <h3>About us</h3>
            <span>Foony Woony Games is a game developing and publishing organisation based in Denmark and founded in 2015. Though small, Foony Woony Games is extremely passionate and devoted to game design and development.</span>
        </div>
        <div className="description-item">
            <h3>Our goals</h3>
            <span>Foony Woony Games strives to build interesting games, stories and worlds to be experienced and explored. To achieve this, the primary focus of Foony Woony Games is experimentation and pushing limits. This means games only release when they are deemed up to Foony Woony Games standards.</span>
            <h3>Contact us</h3>
            <span>All relevant contact information can be found at the bottom of this page.</span>
        </div>
      </div>
      
      <div className="/news">
        <h1>Recent news</h1>
        <Link to="news" className="link-button">
            View all news
        </Link>
        {displayArticle(Object.keys(articledata).length - 1)}
      </div>

      <div className="games">
        <div className="divider" />
        <h1>Featured games</h1>
        {displayGame("arisen")}
      </div>

      <div className="contact">
        <h1>Contact</h1>
        <div className="contact-item">
            <span className="contact-item-title">
                Customer support
            </span>
            <a href="mailto:support@foonywoonygames.net" className="contact-item-value">
                support@foonywoonygames.net
            </a>
        </div>
        <div className="contact-item">
            <span className="contact-item-title">
                Business inquiries
            </span>
            <a href="mailto:contact@foonywoonygames.net" className="contact-item-value">
                contact@foonywoonygames.net
            </a>
        </div>
        <div className="contact-item">
            <span className="contact-item-title">
                Administrator
            </span>
            <a href="mailto:parquelle@foonywoonygames.net" className="contact-item-value">
                parquelle@foonywoonygames.net
            </a>
        </div>
        <div className="contact-socials">
            <a href="https://www.youtube.com/@foonywoonygames" class="contact-socials-button"><YouTubeIcon /></a>
            <a href="https://www.facebook.com/foonywoonygames" class="contact-socials-button"><FacebookIcon /></a>
            <a href="https://www.twitter.com/foonywoonygames" class="contact-socials-button"><TwitterIcon /></a>
            <a href="https://www.instagram.com/foonywoonygames" class="contact-socials-button"><InstagramIcon /></a>
            <a href="https://www.linkedin.com/company/foonywoonygames" class="contact-socials-button"><LinkedInIcon /></a>
        </div>
      </div>
    </div>
  );
};
  
export default Home;