import './stylesheets/news.css';
import {setTitle} from '../App.js';
import {useEffect, useState} from "react";
import {formatTime} from '../scripts/formatTime';
import {Link} from 'react-router-dom';

const News = () => {
    const [articledata, setArticleData] = useState();

    async function fetchArticles() {
      console.log(fetch('/data/articles.json', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      },
      ).then(response => {
        if(response.ok) {
          response.json().then(json => {
            setArticleData(json);
          });
        }
      }));
    }
  
    useEffect(() => {
        fetchArticles();
    }, []);

    function displayArticle(article) {
        console.log(new Date().getTime());
        let icon =  "/img/foonywoonygames/icon.png";
        if(articledata[article]['gameId'] != undefined) icon =  "./img/game/" + articledata[article]['gameId'] + "/icon.png";

        return(
            <Link to={"/article/" + article}>
                <div className="article-item">
                    <div className="article-thumbnail">
                        <img src={"/img/thumbnail/" + article +".png"}/>
                    </div>
                    <div className="article-content">
                        <span className="article-title">{articledata[article]['title']}</span>
                        <div className="article-icon">
                            <img src={icon} />
                        </div>
                        <span className="article-date">{formatTime(articledata[article]['publishDate'])}</span>
                        <span className="article-summary">{articledata[article]['summary']}</span>
                    </div>
                </div>
            </Link>
        );
    }

    setTitle("News");
    if(articledata != undefined)
    return (
        <div className="article-list">
            <h1>News</h1>
            {Object.keys(articledata).map((loopArticle) => (
                displayArticle(loopArticle)
            ))}
        </div>
    );
  };
    
  export default News;