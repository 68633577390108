import {useParams} from "react-router-dom"
import {useEffect, useState} from "react";
import ReactHtmlParser from 'html-react-parser'; 
import './stylesheets/article.css';
import {setTitle} from '../App.js';
import {formatTime} from '../scripts/formatTime';

const Article = () => {
    const {articleid} = useParams();
    const [article, setArticle] = useState();

    async function fetchArticle() {
      console.log(fetch('/data/articles.json', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      },
      ).then(response => {
        if(response.ok) {
          response.json().then(json => {
            console.log(json);
            setArticle(json[articleid]);
          });
        }
      }));
    }
  
    useEffect(() => {
        fetchArticle();
    }, []);

    if(article === undefined) {
        return(<h1>Loading</h1>);
    }
    setTitle(article['title']);
    let icon =  "/img/foonywoonygames/icon.png";
    if(article['gameId'] != undefined) icon =  "../img/game/" + article['gameId'] + "/icon.png";

    var showdown  = require('showdown'),
    converter = new showdown.Converter(),
    text      = article['content'],
    html      = converter.makeHtml(text);
    
    return (
        <div className="article-container">
        <img src="/img/thumbnail/0.png" id="article-thumbnail"/>
        <div id="article-date">
          <div id="article-icon">
            <img src={icon} />
          </div>
          Published {formatTime(article['publishDate'])}
        </div>
        <h1 id="article-title">{article['title']}</h1>
            <span id="article-content">
                {ReactHtmlParser(html)}
            </span>
        </div>
    );
  };
    
  export default Article;