import './pages/stylesheets/_interface.css';
import './pages/stylesheets/_display.css';
import { HashRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import News from './pages/news'
import Article from './pages/article'
import Arisen from './pages/arisen'
import HomeIcon from '@mui/icons-material/Home';

export function setTitle(title, noSuffix) {
  if(noSuffix) document.title = title;
  else document.title = title + ' | Foony Woony Games';
}

function App() {
  return(
    <HashRouter>
    <a href="/" id="home-button"><HomeIcon /></a>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="/news" element={<News />} />
          <Route path="/article/:articleid" element={<Article />} />
          <Route path="/arisen" element={<Arisen />} />
        </Route>
      </Routes>
    </HashRouter>
  )
}

export default App;
