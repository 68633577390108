import {useParams} from "react-router-dom"
import {useEffect, useState} from "react";
import './stylesheets/game/arisen.css';
import {setTitle} from '../App.js';

const Arisen = () => {
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = '/img/game/arisen/icon.png';
  }, []);

  setTitle("The Realm of Order: Arisen", true);
    return (
      <div id="container">
        <img src="/img/game/arisen/logo.png" id="logo" />

        <h2>This page is still under construction.</h2>
        <h3>Please refer to the game's <a href="https://www.indiedb.com/games/the-realm-of-order-arisen">IndieDB page</a> for the time being.</h3>
      </div>
    );
  };
    
  export default Arisen;