export function formatTime(timeToFormat) {

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var currentTime = new Date().getTime();
    var elapsedTime = currentTime - timeToFormat;

    if (elapsedTime < 10000) {
         return 'Just now'  
    }

    else if (elapsedTime < msPerMinute) {
         return Math.round(elapsedTime / 1000) + ' seconds ago';   
    }

    else if (elapsedTime < msPerHour) {
         return Math.round(elapsedTime / msPerMinute) + ' minutes ago';   
    }

    else if (elapsedTime < msPerDay ) {
         return Math.round(elapsedTime / msPerHour ) + ' hours ago';   
    }

    else if (elapsedTime < msPerMonth) {
        return Math.round(elapsedTime / msPerDay) + ' days ago';   
    }

    else if (elapsedTime < msPerYear) {
        return Math.round(elapsedTime / msPerMonth) + ' months ago';   
    }

    else {
        return Math.round(elapsedTime / msPerYear ) + ' years ago';   
    }
}